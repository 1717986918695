import React from "react";
import theme from "theme";
import { Theme, Link, Text, Span, Icon, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import { MdArrowDownward } from "react-icons/md";
import { FaInstagram, FaMapMarkerAlt, FaFacebookSquare } from "react-icons/fa";
import {BiFoodMenu} from "react-icons/all";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Nit Noi Coffee & more
			</title>
			<meta name={"description"} content={"Delicious breakfast all day!"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/65e601921d712a0023313342/images/IMG_9008.jpeg?v=2024-03-04T17:41:36.772Z) center/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			position="static"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Falling in love with
					</Text>
					<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
						<Span
							color="#fff9f5"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nit Noi Coffee & more
						</Span>
					</Text>
				</StackItem>
			</Stack>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					More about us
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Welcome to Nit Noi Coffee & more
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="720px"
				>
					Indulge in delicious breakfast offerings that will uplift your spirits and fuel your day with energy. Our specialties include fresh waffles, aromatic granola, and unbeatable Vietnamese coffee.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<a href={'https://drive.google.com/file/d/1LmmG7hnqqoD2rtc5XOOmt3bDnGiJCqIv/view?usp=sharing'} style={{ textDecoration: 'none', color: '#333' }} target={'_blank'}>
					<Box
						padding="45px 45px"
						lg-padding="45px 30px"
						md-padding="45px 45px"
						background="#FFFFFF"
						border-radius="24px"
						justify-content="flex-start"
						flex-direction="column"
						display="flex"
					>
						<Icon
							category="fa"
							icon={BiFoodMenu}
							margin="0px 0px 30px 0px"
							color="--dark"
							size="48px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Delivery menu
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Prices and photos
						</Text>
					</Box>
				</a>
				<a href={'https://www.instagram.com/nitnoi_coffee'} style={{ textDecoration: 'none', color: '#333' }} target={'_blank'}>
					<Box
						padding="45px 45px"
						lg-padding="45px 30px"
						md-padding="45px 45px"
						background="#FFFFFF"
						border-radius="24px"
						justify-content="flex-start"
						flex-direction="column"
						display="flex"
					>
						<Icon
							category="fa"
							icon={FaInstagram}
							margin="0px 0px 30px 0px"
							color="--dark"
							size="48px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Instagram
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Actual photos and videos!
						</Text>
					</Box>
				</a>
				<a href={'https://maps.app.goo.gl/zVpmMtgoKmjA7w3Q7'} style={{ textDecoration: 'none', color: '#333' }} target={'_blank'}>
					<Box
						padding="45px 45px"
						lg-padding="45px 30px"
						md-padding="45px 45px"
						background="#FFFFFF"
						border-radius="24px"
						justify-content="flex-start"
						flex-direction="column"
						display="flex"
					>
						<Icon
							category="fa"
							icon={FaMapMarkerAlt}
							margin="0px 0px 30px 0px"
							color="--dark"
							size="48px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Google Maps
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Location and reviews
						</Text>
					</Box>
				</a>
				<a href={'https://www.facebook.com/profile.php?id=61551732757346'} style={{ textDecoration: 'none', color: '#333' }} target={'_blank'}>
					<Box
						padding="45px 45px"
						lg-padding="45px 30px"
						md-padding="45px 45px"
						background="#FFFFFF"
						border-radius="24px"
						justify-content="flex-start"
						flex-direction="column"
						display="flex"
					>
						<Icon
							category="fa"
							icon={FaFacebookSquare}
							margin="0px 0px 30px 0px"
							color="--dark"
							size="48px"
						/>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Facebook
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD3"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Photos and videos
						</Text>
					</Box>
				</a>
			</Box>
		</Section>
		<Section background="--color-darkL1" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contacts{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Every day
					<br />
					09:00 - 19:00
				</Text>
				<Link
					href="mailto:blank?info@nit-noi.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@nit-noi.com
				</Link>
				<Link
					href="tel:+66802927422"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+66 80-292-7422
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					<a href={'https://maps.app.goo.gl/zVpmMtgoKmjA7w3Q7'} style={{ color: '#fff' }} target={'_blank'}>
						Thailand, Phuket, Wichit, Anusorn Road, 31/23
					</a>
				</Text>
			</Box>
		</Section>
	</Theme>;
});
